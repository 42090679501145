import * as React from "react";
import styled from "@emotion/styled";

import * as Icons from '../../icons';

const layoutWidth = 1280;
const layoutPadding = '1em';

const ImageBackgroundWrapper = styled.div`
  position: relative;
  overflow: hidden;
`;
const ImageBackgroundBlock = styled.div`
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  /* filter: blur(3px); */
  /* transform: scale(1.01); */
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
const ContentBlock = styled.div`
  padding: 0 ${layoutPadding};
  max-width: ${layoutWidth}px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
`;
// @todo DRY
const TextBlock = styled.div`
  /* text-align: justify; */

  &:after {
    content: ".";
    visibility: hidden;
    display: block;
    height: 0;
    clear: both;
  }

  > * + * {
    margin-top: 1em;
  }

  a {
    border-bottom: 5px dotted;

    &:hover {
      border-bottom-style: solid;
    }
  }

  strong {
    font-weight: bold;
  }
`;

const WebdevBlock = styled.div`
  color: rgba(255, 255, 255, .9);
  /* font-family: monospace; */
  font-family: 'Inconsolata', monospace;
  line-height: 1.25;
  box-shadow: 0 0 2em rgba(0, 0, 0, .5);
`;
const ConsoleTopPanel = styled.div`
  background-color: rgba(0, 0, 0, .66);
  display: flex;
  justify-content: flex-end;
  padding: .5em;
  margin-bottom: 1px;

  > * + * {
    margin-left: .5em;
  }
`;
const ConsoleButton = styled.div`
  background-color: rgba(255, 255, 255, .25);
  width: 1em;
  height: 1em;
`;
const ConsoleMinimize = styled(ConsoleButton)`
  height: .25em;
  margin-top: .75em;
`;
const ConsoleMaximize = styled(ConsoleButton)`
`;
const ConsoleClose = styled(ConsoleButton)`
  background-color: hsla(0, 50%, 50%, .25);
  border-radius: 100%;
`;
const ConsoleBody = styled.div`
  background-color: rgba(0, 0, 0, .66);
  padding: 2em;
`;

const CodeTypeScript = styled.strong`background-color: #294e80; color: #fff;`;
const CodeReact = styled.strong`background-color: #282c34; color: #61dafb;`;
const CodeMobX = styled.strong`background-color: #de5c10; color: #fff;`;
const CodeStyled = styled.strong`color: #C865B9;`;
const CodeWebpack = styled.strong`background-color: #2b3a42; color: #fff;`;

const CodeElectron = styled.strong`background-color: #272a38; color: #aaebfa;`;
const CodeGatsby = styled.strong`background-color: #663399; color: #fff;`;

const CodeRedux = styled.strong`background-color: #764abc; color: #fff;`;
const CodeAngular = styled.strong`background-color: #c3002f; color: #fff;`;
const CodeGrunt = styled.strong`background-color: #e48632; color: #453015;`;
const CodeGulp = styled.strong`background-color: #cf4647; color: #fff;`;
const CodeJquery = styled.strong`color: #0769ad;`;
const CodeSass = styled.strong`color: #c8679b;`;
const CodeBower = styled.strong`background-color: #ffcc2f; color: #ef5734;`;

const CodeGraph = styled.strong`color: #e535ab;`;
const CodeFlutter = styled.strong`color: #5fc6fd;`;
const CodeWasm = styled.strong`background-color: #6750f4; color: #fff;`;
const CodeRust = styled.strong`background-color: #fff; color: #000;`;

const CodeNode = styled.strong`background-color: #43853d; color: #fff;`;
const CodeMongo = styled.strong`background-color: #13aa52; color: #fff;`;
const CodeMySQL1 = styled.span`color: #00758f;`;
const CodeMySQL2 = styled.span`color: #f29111;`;
const CodeDocker = styled.strong`color: #2496ed;`;

const Emphasis = styled.em`text-decoration: underline;`;

export default function HomePageEditor(props: { extended: boolean; content?: React.FC }) {
    const {content: Content} = props;

    return (
        <div>
            <ImageBackgroundWrapper>
                <ImageBackgroundBlock style={{ backgroundImage: 'url(/images/editor2.png)' }} />

                <ContentBlock>
                    <div style={{ padding: '3em 0' }}>
                        <WebdevBlock>
                            <ConsoleTopPanel>
                                <ConsoleMinimize />
                                <ConsoleMaximize />
                                <ConsoleClose />
                            </ConsoleTopPanel>

                            <ConsoleBody>
                                <TextBlock>
                                    <p>I've been working with web apps for a living for a few years now.</p>

                                    <p>My battle-tested front-end stack of choice has been <CodeTypeScript>TypeScript</CodeTypeScript> as a statically-typed alternative to JavaScript, <CodeReact>React</CodeReact> for structuring content (templates/components), <CodeMobX>MobX</CodeMobX> for state management and <CodeStyled>styled-components</CodeStyled> for CSS, all bundled with <CodeWebpack>Webpack</CodeWebpack>.</p>

                                    {
                                        Content
                                        &&
                                        <Content />
                                    }

                                    {
                                        props.extended
                                        &&
                                        <>
                                            <p>I've also built desktop apps with <CodeElectron>Electron</CodeElectron> and static websites with <CodeGatsby>Gatsby</CodeGatsby>.</p>

                                            <p>On the server-side I've dabbled with <CodeNode>NodeJS</CodeNode> (Express, Hapi), <CodeMongo>MongoDB</CodeMongo>, <strong><CodeMySQL1>My</CodeMySQL1><CodeMySQL2>SQL</CodeMySQL2></strong> and tools like <CodeDocker>Docker</CodeDocker>.</p>

                                            <p>I used to work with React &amp; <CodeRedux>Redux</CodeRedux>, the first version of <CodeAngular>Angular</CodeAngular> and tools like <CodeGrunt>Grunt</CodeGrunt> and <CodeGulp>Gulp</CodeGulp>. I remember the old days when layouts were built using floats instead of <Emphasis>flexbox</Emphasis> and <Emphasis>grid</Emphasis>, when <CodeJquery>jQuery</CodeJquery> and <CodeSass>Sass</CodeSass> were in standard use over vanilla JS and CSS, and when <CodeBower>Bower</CodeBower> became a rescue from managing libraries manually.</p>

                                            <p>Nowadays I try to get to know <CodeGraph>GraphQL</CodeGraph> and <CodeFlutter>Flutter</CodeFlutter>. I also experiment with <CodeWasm>WebAssembly</CodeWasm> using <CodeRust>Rust</CodeRust>.</p>
                                        </>
                                    }
                                </TextBlock>
                            </ConsoleBody>
                        </WebdevBlock>
                    </div>
                </ContentBlock>
            </ImageBackgroundWrapper>
        </div>
    );
}
